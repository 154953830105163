<template>
  <v-snackbar
    app
    v-model="show"
    bottom
    :timeout="timeout"
    :min-width="minWidth"
    :color="color"
  >
    {{ text }}
    <template v-if="action" v-slot:action="{ attrs }">
      <v-btn text v-bind="attrs" @click.stop="action">
        {{ actionText }}
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "TSnackbar",
  computed: {
    ...mapGetters({
      timeout: "snackbar/timeout",
      text: "snackbar/text",
      color: "snackbar/color",
      minWidth: "snackbar/minWidth",
      action: "snackbar/action",
      actionText: "snackbar/actionText",
    }),
    show: {
      get() {
        return this.$store.getters["snackbar/show"];
      },
      set() {
        this.$store.dispatch("snackbar/hide");
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.v-snack {
  &::v-deep .v-snack__wrapper {
    border-radius: 35px;
    .v-snack__content {
      text-align: center !important;
      font-size: 14px;
    }
  }
}
</style>
